<template>
    <div>
        <LandHeader />
        <BreadCrumb />
        <div class="center land-box land-info">
            <div class="demand-info">
                <h2>{{ data.title }}</h2>
              <el-button v-if="data.auditStatus !==2" type="danger" class="fav-btn" style="font-size: 18px;color: whitesmoke;float: right;" >未审核</el-button>
                <div class="demand-info__time mb30">更新时间：{{ data.updateTime }}</div>
                <el-descriptions title="求购信息" direction="vertical" :column="3" border>
                    <el-descriptions-item label="所属行政区">{{ findLabel(land_area,data.district) }}</el-descriptions-item>
                    <el-descriptions-item label="土地性质">{{ findLabel(land_type,data.landType) }}</el-descriptions-item>
                    <el-descriptions-item label="土地用途">{{ findLabel(demand_purpose,data.demandPurpose) }}</el-descriptions-item>
                    <el-descriptions-item label="需求面积">
                        {{ data.landSizeMin }}平方米 - {{ data.landSizeMax }}平方米
                    </el-descriptions-item>
                    <el-descriptions-item label="剩余使用年限">
                        {{ data.yearLimitMin }}年 - {{ data.yearLimitMax }}年
                    </el-descriptions-item>
                    <el-descriptions-item label="交易方式 ">求购</el-descriptions-item>
<!--                    <el-descriptions-item label="是否有地上建筑物">{{ findLabel(yes_or_no,data.district) }}</el-descriptions-item>-->
<!--                    <el-descriptions-item label="地上建筑面积">0平方米</el-descriptions-item>-->
<!--                    <el-descriptions-item label="地上建筑类型"></el-descriptions-item>-->
                  <el-descriptions-item label="期望价格" :span="3" v-if="data.expectPriceLow || data.expectPriceHigh">
                     <span class="price"><i v-if="data.expectPriceLow">
                       {{ data.expectPriceLow }}万元
                     </i>
                       <i v-else>0</i>
                       -
                     <i v-if="data.expectPriceHigh">
                       {{ data.expectPriceHigh }}万元
                     </i>
                       <i v-else>不限</i>
                     </span></el-descriptions-item>

                  <el-descriptions-item label="期望价格" :span="3" v-else>
                    <span class="price">面议</span></el-descriptions-item>
                    <el-descriptions-item label="详细说明" :span="3">{{ data.extraData||'无' }}</el-descriptions-item>
                    <el-descriptions-item label="联系人">{{ data.contact }}</el-descriptions-item>
                    <el-descriptions-item label="联系方式">
                        <el-tooltip :content="data.contactMobile" placement="top" effect="light">
                        <el-link>查看联系方式 </el-link>
                        </el-tooltip>
                    </el-descriptions-item>
                </el-descriptions>
            </div>

        </div>
        <footer2 />
    </div>
</template>
<script lang="ts">
import LandHeader from "@/components/nav";
import footer2 from "components/footer.vue";
import CommonService from "../service/CommonService";
import { useDict, findLabel } from "@/hooks/dict";
import '@/assets/css/land-info.scss'

export default {
    name: "LandInfo",
    components: {
        LandHeader,
        footer2,
    },
    data() {
        return {
            data: {
                title: "",
                updateTime: '',
                district: '',
                landType: '',
                demandPurpose: '',
                landSizeMin: '',
                landSizeMax: '',
                yearLimitMin: '',
                yearLimitMax: '',
                extraData: '',
                expectPriceLow: '',
                expectPriceHigh: '',
                contact: '',
                contactMobile: '',
            },
        };
    },
    computed: {
        landPics() {
            return this.landInfo.landPics.split(",");
        },
        land_type() {
            return this.getDict("land_type");
        },
        demand_purpose() {
            return this.getDict("demand_purpose");
        },
        transaction_way() {
            return this.getDict("transaction_way");
        },
        land_area() {
            return this.getDict("land_area");
        },
        yes_or_no() {
          return this.getDict("yes_no_type");
        },
        land_owner_type() {
            return this.getDict("land_owner_type");
        },
        land_license_type() {
            return this.getDict("land_license_type");
        },
    },
    mounted() {
        this.loadDemandInfo();
    },
    methods: {
        loadDemandInfo() {
            CommonService.getDemandDetail(this.$route.params.id).then((res) => {
                if (res.code === 0) {
                    // console.log(res.data);
                    this.data = res.data;
                }
            });
        },
        getDict(dictName) {
            return useDict(dictName)[dictName].value;
        },
        findLabel,
    },
};  
</script>
<style lang="scss" scoped>
.demand-info {
    background: #fff;
    padding: 20px;

    h2 {
        color: #333;
        font-weight: 500;
    }

    .demand-info__time {
        color: #999;
        font-size: 14px;
        margin-top: 10px;
    }

    .price {
        color: #fb5424;
        font-size: 18px;
        font-weight: 500;
    }
}
</style>